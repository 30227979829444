.container {
    position: relative;
}

.container, .container .mapboxgl-container {
    height: 300px;
}

@media (min-width: 992px) {
    .container, .container .mapboxgl-container {
        height: calc(100vh - 140px);
    }
}

.marker {
    text-align: center;
    width: 50px;
    display: inline-block;
    position: relative;
    top: -5px;
}

.markerText {
    width: 100%;
    position: absolute;
    left: 1px;
    top: 6px;
    z-index: 100;
    color: white;
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: 0.85rem;
    cursor: pointer;
}

.markerIcon {
    font-size: 2.5rem;
    color: var(--bs-gray-700);
    transform: scale(1.1,1);
    cursor: pointer;
}

.spinner {
    position: absolute;
    z-index: 1000;
    top: 5px;
    left: 5px;
    color: var(--bs-gray-700);
}